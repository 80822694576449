import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import Typed from "typed.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
  const typedElement = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: [
        "Radio comunicación",
        "Seguridad",
        "Inspección",
        "Drones",
        "Equipamiento de Vehículos",
        "Puntos de repetición",
      ],
      typeSpeed: 80,
      backSpeed: 40,
      backDelay: 1000,
      loop: true,
    });

    return () => typed.destroy();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <div id="hero" className="relative w-full h-auto overflow-hidden">
      <Slider
        {...settings}
        className="[&_.slick-dots]:bottom-4 [&_.slick-dots_li_button:before]:text-white"
      >
        <div className="relative w-full">
          <div
            className="w-full"
            style={{ paddingTop: "calc(720 / 1920 * 100%)" }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Portadas%2Fportada%20sin%20efecto%20rs.png?alt=media&token=59a7b83b-92b7-4259-a070-7ea25ae0baa0"
              alt="Slide 1"
              className="absolute top-0 left-0 w-full h-full object-contain"
            />
          </div>
        </div>
      </Slider>

      <div className="absolute inset-0 bg-black/40 flex flex-col justify-center items-center px-5 text-center">
        <h1 className="font-roboto text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium mb-3 text-white tracking-wider">
          Expertos en
        </h1>
        <span
          ref={typedElement}
          className="font-roboto text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-6 tracking-wide"
        ></span>
      </div>
    </div>
  );
};

export default Hero;
