import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

const DatosPersonales = ({ solicitudId }) => {
  const [form, setForm] = useState({
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombres: "",
    edad: "",
    domicilio: "",
    colonia: "",
    codigoPostal: "",
    telefono: "",
    lugarNacimiento: "",
    fechaNacimiento: "",
    nacionalidad: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { datosPersonales: form });

      setSuccess(true);
    } catch (err) {
      console.error("Error actualizando los datos personales:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={form.apellidoPaterno}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Apellido Materno"
            name="apellidoMaterno"
            value={form.apellidoMaterno}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre(s)"
            name="nombres"
            value={form.nombres}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Edad"
            name="edad"
            value={form.edad}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Teléfono"
            name="telefono"
            value={form.telefono}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Domicilio"
            name="domicilio"
            value={form.domicilio}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Colonia"
            name="colonia"
            value={form.colonia}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Código Postal"
            name="codigoPostal"
            value={form.codigoPostal}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Lugar de Nacimiento"
            name="lugarNacimiento"
            value={form.lugarNacimiento}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            name="fechaNacimiento"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={form.fechaNacimiento}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Nacionalidad"
            name="nacionalidad"
            value={form.nacionalidad}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
        fullWidth
        sx={{ marginTop: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Guardar Datos"}
      </Button>

      {/* Notificaciones */}
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success">¡Datos personales guardados con éxito!</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={4000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">Hubo un error al guardar los datos.</Alert>
      </Snackbar>
    </Box>
  );
};

export default DatosPersonales;