import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const NuevaCategoria = () => {
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState("");
  const [subCategorias, setSubCategorias] = useState([]);
  const [message, setMessage] = useState("");
  const [nextId, setNextId] = useState(1);
  const [categoriasExistentes, setCategoriasExistentes] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [subCategoriasSeleccionadas, setSubCategoriasSeleccionadas] = useState(
    []
  );

  useEffect(() => {
    const fetchCategorias = () => {
      const q = query(collection(db, "categorias"), orderBy("id", "asc"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const categorias = snapshot.docs.map((doc) => ({
          id: doc.data().id,
          nombre: doc.data().nombre,
          subCategorias: doc.data().subCategorias || [],
        }));
        setCategoriasExistentes(categorias);
        if (categorias.length > 0) {
          setNextId(categorias[categorias.length - 1].id + 1);
        }
      });
      return unsubscribe;
    };

    fetchCategorias();
  }, []);

  const handleAddCategoria = async () => {
    if (!categoria.trim() && !categoriaSeleccionada) {
      setMessage("El nombre de la categoría es obligatorio.");
      return;
    }

    try {
      if (categoriaSeleccionada) {
        const categoriaDocRef = doc(db, "categorias", categoriaSeleccionada);
        const categoriaSeleccionadaDoc = categoriasExistentes.find(
          (cat) => cat.id === parseInt(categoriaSeleccionada)
        );

        const nuevasSubCategorias = [
          ...categoriaSeleccionadaDoc.subCategorias,
          ...subCategoriasSeleccionadas,
          ...subCategorias,
        ];

        await updateDoc(categoriaDocRef, {
          subCategorias: [...new Set(nuevasSubCategorias)], // Evitar duplicados
        });

        setMessage(
          "Subcategorías agregadas a la categoría existente con éxito!"
        );
      } else {
        const newDocRef = doc(db, "categorias", nextId.toString());

        await setDoc(newDocRef, {
          id: nextId,
          nombre: categoria,
          subCategorias,
        });

        setMessage("Categoría agregada con éxito!");
        setNextId(nextId + 1);
      }

      setCategoria("");
      setSubCategoria("");
      setSubCategorias([]);
      setSubCategoriasSeleccionadas([]);
      setCategoriaSeleccionada("");
    } catch (error) {
      console.error("Error al agregar la categoría:", error);
      setMessage("Error al agregar la categoría. Intenta nuevamente.");
    }
  };

  const handleAddSubCategoria = () => {
    if (subCategoria.trim()) {
      setSubCategorias((prev) => [...prev, subCategoria]);
      setSubCategoria("");
    }
  };

  const handleRemoveSubCategoria = (index) => {
    setSubCategorias((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectSubCategoria = (subCat) => {
    setSubCategoriasSeleccionadas((prev) =>
      prev.includes(subCat)
        ? prev.filter((item) => item !== subCat)
        : [...prev, subCat]
    );
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Crear Nueva Categoría o Agregar Subcategorías
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Seleccionar Categoría
          </label>
          <select
            value={categoriaSeleccionada}
            onChange={(e) => {
              setCategoriaSeleccionada(e.target.value);
              if (e.target.value) {
                const selected = categoriasExistentes.find(
                  (cat) => cat.id.toString() === e.target.value
                );
                setSubCategoriasSeleccionadas(selected?.subCategorias || []);
              } else {
                setSubCategoriasSeleccionadas([]);
              }
            }}
            className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
          >
            <option value="">
              <em>Nueva Categoría</em>
            </option>
            {categoriasExistentes.map((cat) => (
              <option key={cat.id} value={cat.id.toString()}>
                {cat.nombre}
              </option>
            ))}
          </select>
        </div>

        {!categoriaSeleccionada && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Nombre de la Categoría
            </label>
            <input
              type="text"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
              className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
            />
          </div>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Nombre de la Subcategoría
          </label>
          <input
            type="text"
            value={subCategoria}
            onChange={(e) => setSubCategoria(e.target.value)}
            className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
          />
          <button
            onClick={handleAddSubCategoria}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Agregar Subcategoría
          </button>
        </div>

        {categoriaSeleccionada && (
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-800 mb-2">
              Subcategorías Existentes:
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {categoriasExistentes
                .find((cat) => cat.id.toString() === categoriaSeleccionada)
                ?.subCategorias.map((subCat, index) => (
                  <div
                    key={index}
                    className={`relative bg-gray-50 border border-gray-300 p-3 rounded-lg shadow cursor-pointer ${
                      subCategoriasSeleccionadas.includes(subCat)
                        ? "bg-blue-100 border-blue-500"
                        : ""
                    }`}
                    onClick={() => handleSelectSubCategoria(subCat)}
                  >
                    <p className="text-sm text-gray-800">{subCat}</p>
                  </div>
                ))}
            </div>
          </div>
        )}

        {subCategorias.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-800 mb-2">
              Subcategorías Nuevas:
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {subCategorias.map((subCat, index) => (
                <div
                  key={index}
                  className="relative bg-gray-50 border border-gray-300 p-3 rounded-lg shadow"
                >
                  <p className="text-sm text-gray-800">{subCat}</p>
                  <button
                    onClick={() => handleRemoveSubCategoria(index)}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-600 transition"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <button
          onClick={handleAddCategoria}
          className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
        >
          {categoriaSeleccionada ? "Agregar Subcategorías" : "Crear Categoría"}
        </button>

        {message && <p className="mt-4 text-sm text-gray-700">{message}</p>}
      </div>
    </div>
  );
};

export default NuevaCategoria;
