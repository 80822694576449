import React from "react";
import QrScanner from "react-qr-scanner";
import { QrCode, X } from "lucide-react"; // Importar los iconos de Lucide Icons

const EscanerQR = ({
  showQrScanner,
  setShowQrScanner,
  setNumInventario,
  setMessage,
}) => {
  const handleScan = (data) => {
    if (data) {
      const audio = new Audio(
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/sounds%2FBarcode%20scanner%20beep%20sound%20(sound%20effect)%20-%20sound%20effects%20(youtube).mp3?alt=media&token=eb15bedc-8447-4733-88b3-a0ba017846aa"
      ); // URL del sonido
      audio.play(); // Reproducir sonido al escanear
      setNumInventario(data.text); // Actualiza el número de inventario
      setMessage(`Código QR escaneado: ${data.text}`);
      setShowQrScanner(false);
    }
  };

  const handleError = (err) => {
    console.error("Error al escanear el código QR:", err);
    setMessage("Error al escanear el código QR. Intenta nuevamente.");
  };

  return (
    <div className="mb-4">
      {/* Botón para abrir el escáner */}
      <button
        onClick={() => setShowQrScanner(true)}
        className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        <QrCode className="w-5 h-5 mr-2" />
        Escanear
      </button>

      {showQrScanner && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-4/5 max-w-md animate-fade-in relative">
            <div className="p-6">
              {/* Botón para cerrar el escáner */}
              <button
                onClick={() => setShowQrScanner(false)}
                className="absolute top-4 right-4 text-red-500 hover:text-red-600 transition duration-300"
              >
                <X className="w-6 h-6" />
              </button>

              <h2 className="text-lg font-semibold text-gray-800 text-center mb-4">
                Escanea tu Código QR
              </h2>

              <div className="flex justify-center">
                <QrScanner
                  delay={300}
                  style={{ width: "100%", height: "auto" }}
                  onError={handleError}
                  onScan={handleScan}
                  constraints={{
                    video: { facingMode: { exact: "environment" } },
                  }}
                />
              </div>

              <p className="text-sm text-gray-600 mt-4 text-center">
                Asegúrate de que el código esté dentro del marco de la cámara.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EscanerQR;
