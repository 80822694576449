import React, { useState, useEffect, useRef } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import { collection, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../../firebase";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

// Configuración de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement);

const Analitica = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hourlyData, setHourlyData] = useState([]);
  const [peakHours, setPeakHours] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]); // Selección múltiple
  const audioRef = useRef(null);

  // Fetch vehicles in real-time
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "vehicles"),
      (snapshot) => {
        let vehicleList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordenar por fecha de más reciente a más antiguo
        vehicleList = vehicleList.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );

        if (vehicles.length < vehicleList.length) {
          playSound(); // Reproducir sonido si hay un nuevo vehículo
        }

        setVehicles(vehicleList);
        analyzeVehicles(vehicleList); // Analizar los autos
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching vehicles:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, [vehicles]);

  // Reproducir sonido al detectar un nuevo auto
  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Error reproduciendo el sonido:", error);
      });
    }
  };

  // Análisis de datos del día
  const analyzeVehicles = (vehicles) => {
    const today = new Date();
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const endOfDay = new Date(startOfDay);
    endOfDay.setDate(startOfDay.getDate() + 1);

    // Filtrar autos del día
    const dailyVehicles = vehicles.filter(
      (vehicle) =>
        new Date(vehicle.timestamp) >= startOfDay &&
        new Date(vehicle.timestamp) < endOfDay
    );

    // Agrupar autos por hora
    const hourlyCount = Array(24).fill(0);
    dailyVehicles.forEach((vehicle) => {
      const hour = new Date(vehicle.timestamp).getHours();
      hourlyCount[hour]++;
    });

    setHourlyData(hourlyCount);

    // Identificar horas pico
    const maxCount = Math.max(...hourlyCount);
    const peak = hourlyCount
      .map((count, index) => (count === maxCount ? index : null))
      .filter((hour) => hour !== null);

    setPeakHours(peak);
  };

  const handleSelectVehicle = (id) => {
    setSelectedVehicles((prev) =>
      prev.includes(id) ? prev.filter((vid) => vid !== id) : [...prev, id]
    );
  };

  const handleDeleteSelected = async () => {
    const promises = selectedVehicles.map(async (id) => {
      const vehicle = vehicles.find((v) => v.id === id);

      try {
        // Eliminar la imagen de Firebase Storage
        const imageRef = ref(storage, vehicle.image_url);
        await deleteObject(imageRef);

        // Eliminar el documento de Firestore
        await deleteDoc(doc(db, "vehicles", id));
      } catch (error) {
        console.error(`Error eliminando vehículo ${id}:`, error);
      }
    });

    await Promise.all(promises);
    setSelectedVehicles([]); // Limpiar la selección
  };

  // Datos para la gráfica de autos por hora
  const chartData = {
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
    datasets: [
      {
        label: "Autos detectados por hora",
        data: hourlyData,
        backgroundColor: "#0d47a1",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ffffff",
        },
        title: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 1,
          color: "#ffffff",
        },
        title: {
          display: true,
          text: "Cantidad de autos",
          color: "#ffffff",
        },
      },
    },
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#212121",
        }}
      >
        <CircularProgress sx={{ color: "#0d47a1" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#0a0a0a",
        minHeight: "100vh",
        color: "#fff",
      }}
    >
      {/* Audio para detección */}
      <audio ref={audioRef} src="/sounds/detection.mp3" preload="auto" />

      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#f44336" }}>
          Análisis de Vehículos
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteSelected}
          disabled={selectedVehicles.length === 0}
        >
          Eliminar seleccionados ({selectedVehicles.length})
        </Button>
      </Box>

      {/* Gráfica de autos por hora */}
      <Box sx={{ mb: 4, backgroundColor: "#1e1e1e", p: 2, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ color: "#ffcc00", mb: 2 }}>
          Autos detectados por hora
        </Typography>
        <Bar data={chartData} options={chartOptions} />
      </Box>

      {/* Horas pico */}
      <Box sx={{ mb: 3, backgroundColor: "#1e1e1e", p: 2, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ color: "#ffcc00", mb: 1 }}>
          Horas pico del día:
        </Typography>
        {peakHours.length > 0 ? (
          peakHours.map((hour) => (
            <Typography key={hour} sx={{ color: "#90caf9" }}>
              {hour}:00 - {hour + 1}:00
            </Typography>
          ))
        ) : (
          <Typography sx={{ color: "#ffffff" }}>
            Sin actividad destacada
          </Typography>
        )}
      </Box>

      {/* Tarjetas de vehículos */}
      <Grid container spacing={3}>
        {vehicles.map((vehicle) => (
          <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
            <Card
              sx={{
                boxShadow: 5,
                borderRadius: 3,
                backgroundColor: "#1e1e1e",
                color: "#fff",
                position: "relative",
              }}
            >
              <Checkbox
                checked={selectedVehicles.includes(vehicle.id)}
                onChange={() => handleSelectVehicle(vehicle.id)}
                sx={{
                  position: "absolute",
                  top: 8,
                  left: 8,
                  color: "#ffcc00",
                }}
              />
              <CardMedia
                component="img"
                height="200"
                image={vehicle.image_url}
                alt={vehicle.object_type}
                sx={{ borderRadius: "8px 8px 0 0" }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#ffcc00",
                  }}
                >
                  Camera ID: {vehicle.camera_id}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#90caf9",
                    marginBottom: "8px",
                  }}
                >
                  Tipo: {vehicle.object_type}
                </Typography>
                <Typography variant="body2" sx={{ color: "#90caf9" }}>
                  Fecha y hora: {vehicle.timestamp}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Analitica;
