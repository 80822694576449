import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Search,
  QrCode,
  Tag,
  Box,
  User,
  ShoppingBag,
  Image as ImageIcon,
  AlertCircle,
} from "lucide-react";
import EscanerQR from "./NuevoIventario/EscanerQR";

const InventarioCompleto = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [productos, setProductos] = useState([]);
  const [clientes, setClientes] = useState({});
  const [marcas, setMarcas] = useState({});
  const [message, setMessage] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);

  useEffect(() => {
    const fetchAllProductos = async () => {
      try {
        const productosRef = collection(db, "inventory");
        const querySnapshot = await getDocs(productosRef);
        const productosList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosList);
      } catch (error) {
        console.error("Error al obtener el inventario:", error);
        setMessage("Error al obtener el inventario. Intenta nuevamente.");
      }
    };

    const fetchClientes = async () => {
      try {
        const clientesRef = collection(db, "clientes");
        const querySnapshot = await getDocs(clientesRef);
        const clientesMap = {};
        querySnapshot.forEach((doc) => {
          clientesMap[doc.id] = doc.data().Nombre;
        });
        setClientes(clientesMap);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        setMessage("Error al obtener los clientes. Intenta nuevamente.");
      }
    };

    const fetchMarcas = async () => {
      try {
        const marcasRef = collection(db, "marcas");
        const querySnapshot = await getDocs(marcasRef);
        const marcasMap = {};
        querySnapshot.forEach((doc) => {
          marcasMap[doc.id] = doc.data().nombre;
        });
        setMarcas(marcasMap);
      } catch (error) {
        console.error("Error al obtener las marcas:", error);
        setMessage("Error al obtener las marcas. Intenta nuevamente.");
      }
    };

    fetchAllProductos();
    fetchClientes();
    fetchMarcas();
  }, []);

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      setMessage("Por favor, ingrese un término de búsqueda.");
      return;
    }

    try {
      const productosRef = collection(db, "inventory");
      const q = query(productosRef, where("titulo", ">=", searchTerm));
      const querySnapshot = await getDocs(q);
      const productosList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProductos(productosList);

      if (productosList.length === 0) {
        setMessage(
          "No se encontraron productos que coincidan con la búsqueda."
        );
      } else {
        setMessage("");
      }
    } catch (error) {
      console.error("Error al buscar productos:", error);
      setMessage("Error al buscar productos. Intenta nuevamente.");
    }
  };

  return (
    <div className="min-h-screen ">
      <div className="max-w-screen-xl mx-auto w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        {/* Header */}
        <div className="bg-blue-600 text-white p-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold flex items-center gap-3">
            <ShoppingBag className="w-8 h-8" />
            Inventario Completo
          </h1>
        </div>

        {/* Search and QR Section */}
        <div className="p-6 bg-gray-50 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-3 items-center">
            <div className="relative flex-1">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Buscar por número de inventario, título, etc."
                className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none transition"
              />
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleSearch}
                className="flex items-center gap-2 bg-blue-600 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-700 transition"
              >
                <Search className="w-5 h-5" />
                Buscar
              </button>

              <button
                onClick={() => setShowQrScanner(!showQrScanner)}
                className="flex items-center gap-2 bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 transition"
              >
                <QrCode className="w-5 h-5" />
                Escanear
              </button>
            </div>
          </div>

          <EscanerQR
            showQrScanner={showQrScanner}
            setShowQrScanner={setShowQrScanner}
            setNumInventario={setSearchTerm}
            setMessage={setMessage}
          />
        </div>

        {/* Error Message */}
        {message && (
          <div className="p-4 bg-red-50 text-red-700 flex items-center justify-center gap-3">
            <AlertCircle className="w-6 h-6" />
            <p className="text-sm">{message}</p>
          </div>
        )}

        {/* Product Grid */}
        <div className="p-6">
          {productos.length === 0 ? (
            <div className="text-center text-gray-500 py-12">
              <Box className="w-16 h-16 mx-auto mb-4 text-gray-400" />
              <p>No hay productos para mostrar</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {productos.map((producto, index) => (
                <div
                  key={index}
                  className="bg-white border border-gray-200 rounded-xl shadow-lg hover:shadow-xl transition-all overflow-hidden"
                >
                  {/* Product Image */}
                  {producto.imagenes && producto.imagenes.length > 0 ? (
                    <img
                      src={producto.imagenes[0]}
                      alt={`Producto ${index + 1}`}
                      className="w-full h-48 object-cover"
                    />
                  ) : (
                    <div className="w-full h-48 bg-gray-100 flex items-center justify-center">
                      <ImageIcon className="w-12 h-12 text-gray-400" />
                    </div>
                  )}

                  {/* Product Details */}
                  <div className="p-4">
                    <h3 className="text-lg font-bold text-gray-800 mb-2 truncate">
                      {producto.titulo}
                    </h3>

                    <div className="space-y-2 text-sm text-gray-600">
                      <div className="flex items-center gap-2">
                        <Tag className="w-4 h-4 text-blue-500" />
                        <span>
                          <strong>Número:</strong> {producto.numInventario}
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <Box className="w-4 h-4 text-green-500" />
                        <span>
                          <strong>Marca:</strong>{" "}
                          {marcas[producto.marcaId] || "No asignado"}
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <ShoppingBag className="w-4 h-4 text-purple-500" />
                        <span>
                          <strong>Modelo:</strong> {producto.modelo || "N/A"}
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <User className="w-4 h-4 text-red-500" />
                        <span>
                          <strong>Cliente:</strong>{" "}
                          {clientes[producto.clienteId] || "No asignado"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventarioCompleto;
