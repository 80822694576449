import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
} from "@mui/material";
import { addDoc, collection, doc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatosPersonales from "./Steps/DatosPersonales";
import Documentacion from "./Steps/Documentacion";

const NuevaSolicitud = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [contactInfo, setContactInfo] = useState("");
  const [solicitudId, setSolicitudId] = useState(null);
  const [datosPersonales, setDatosPersonales] = useState({});
  const [documentacion, setDocumentacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const steps = ["Información de contacto", "Datos personales", "Documentación", "Finalizado"];

  // Verificar si el contacto ya existe
  const checkExistingSolicitud = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, "Solicitudes"), where("contactInfo", "==", contactInfo));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const existingDoc = querySnapshot.docs[0];
        setSolicitudId(existingDoc.id);
        setDatosPersonales(existingDoc.data().datosPersonales || {});
        setDocumentacion(existingDoc.data().documentacion || {});
        setSuccess(true);
      } else {
        setSolicitudId(null);
        setDatosPersonales({});
        setDocumentacion({});
      }
    } catch (err) {
      console.error("Error verificando el contacto:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!contactInfo) {
        setError(true);
        return;
      }

      // Crear nueva solicitud si no existe
      if (!solicitudId) {
        setLoading(true);
        try {
          const docRef = await addDoc(collection(db, "Solicitudes"), {
            contactInfo,
            createdAt: new Date(),
            datosPersonales: {},
            documentacion: {},
          });

          setSolicitudId(docRef.id);
          setActiveStep((prevStep) => prevStep + 1);
          setSuccess(true);
        } catch (err) {
          console.error("Error creando la solicitud:", err);
          setError(true);
        } finally {
          setLoading(false);
        }
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleUpdateDatosPersonales = async (updatedData) => {
    if (!solicitudId) return;

    setLoading(true);
    try {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { datosPersonales: updatedData });
      setDatosPersonales(updatedData);
      setSuccess(true);
    } catch (err) {
      console.error("Error actualizando los datos personales:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDocumentacion = async (updatedData) => {
    if (!solicitudId) return;

    setLoading(true);
    try {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { documentacion: updatedData });
      setDocumentacion(updatedData);
      setSuccess(true);
    } catch (err) {
      console.error("Error actualizando la documentación:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactInfo) {
      checkExistingSolicitud();
    }
  }, [contactInfo]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Nueva Solicitud
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ marginTop: 4 }}>
        {activeStep === 0 && (
          <>
            <Typography variant="h6">Paso 1: Información de Contacto</Typography>
            <TextField
              fullWidth
              label="Correo Electrónico o Teléfono"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={loading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Continuar"}
            </Button>
          </>
        )}

        {activeStep === 1 && solicitudId && (
          <>
            <Typography variant="h6">Paso 2: Datos Personales</Typography>
            <DatosPersonales
              solicitudId={solicitudId}
              datosIniciales={datosPersonales}
              onUpdate={handleUpdateDatosPersonales}
            />
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ marginTop: 2, marginRight: 1 }}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ marginTop: 2 }}
            >
              Continuar
            </Button>
          </>
        )}

        {activeStep === 2 && solicitudId && (
          <>
            <Typography variant="h6">Paso 3: Documentación</Typography>
            <Documentacion
              solicitudId={solicitudId}
              datosIniciales={documentacion}
              onUpdate={handleUpdateDocumentacion}
            />
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ marginTop: 2, marginRight: 1 }}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ marginTop: 2 }}
            >
              Continuar
            </Button>
          </>
        )}

        {activeStep === 3 && (
          <Typography variant="h6" color="success.main">
            ¡Solicitud completada con éxito!
          </Typography>
        )}
      </Box>

      {/* Notificaciones */}
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success">¡Paso completado con éxito!</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={4000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">Hubo un error, verifica los datos ingresados.</Alert>
      </Snackbar>
    </Box>
  );
};

export default NuevaSolicitud;