import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import InspectionStats from "./InspectionStats";
import ExportToExcel from "./ExportToExcel";

// Función para formatear la fecha desde Timestamp
const formatTimestamp = (timestamp) => {
  const date = timestamp.toDate();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

// Normalizar las fechas para comparar día, mes y año
const normalizeDate = (date) => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);
  return normalizedDate;
};

const InspectionList = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const q = query(collection(db, "inspecciones"));
        const querySnapshot = await getDocs(q);
        const inspectionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        inspectionsData.sort((a, b) => b.Date.toDate() - a.Date.toDate());
        setInspections(inspectionsData);
      } catch (err) {
        console.error("Error al recuperar las inspecciones:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchInspections();
  }, []);

  const filteredInspections = useMemo(() => {
    return inspections.filter((inspection) => {
      const isInUnitSearch = inspection.Unidad.toLowerCase().includes(
        searchTerm.toLowerCase()
      );
      const inspectionDate = inspection.Date.toDate();
      const normalizedStartDate = startDate ? normalizeDate(startDate) : null;
      const normalizedEndDate = endDate ? normalizeDate(endDate) : null;

      const isInDateRange =
        (!normalizedStartDate ||
          normalizeDate(inspectionDate) >= normalizedStartDate) &&
        (!normalizedEndDate ||
          normalizeDate(inspectionDate) <= normalizedEndDate);

      return isInUnitSearch && isInDateRange;
    });
  }, [inspections, searchTerm, startDate, endDate]);

  const handleRowClick = (id) => {
    setSelectedRow(id);
    window.open(`/inspeccion/${id}`, "_blank");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader border-t-4 border-gray-600 animate-spin rounded-full w-12 h-12"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-semibold text-gray-700 mb-6 text-center">
        Lista de Inspecciones
      </h1>

      {/* Estadísticas y Exportar */}
      <div className="mb-6 flex flex-col md:flex-row justify-between items-center gap-4">
        <InspectionStats inspections={filteredInspections} />
        <ExportToExcel inspections={filteredInspections} />
      </div>

      {/* Filtros */}
      <div className="flex flex-wrap gap-4 mb-6">
        <input
          type="text"
          placeholder="Buscar por Unidad"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            label="Fecha inicio"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="relative">
                <input
                  ref={inputRef}
                  {...inputProps}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {InputProps?.endAdornment}
              </div>
            )}
          />
          <DatePicker
            label="Fecha fin"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="relative">
                <input
                  ref={inputRef}
                  {...inputProps}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {InputProps?.endAdornment}
              </div>
            )}
          />
        </LocalizationProvider>
        <button
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            setSearchTerm("");
          }}
          className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Limpiar Filtros
        </button>
      </div>

      {/* Tabla */}
      <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
        <table className="w-full text-left border-collapse">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-4 border-b">Folio</th>
              <th className="p-4 border-b">Nombre</th>
              <th className="p-4 border-b">Fecha</th>
              <th className="p-4 border-b">Técnico</th>
              <th className="p-4 border-b">Estado</th>
              <th className="p-4 border-b">Unidad</th>
              <th className="p-4 border-b">Comentario</th>
              <th className="p-4 border-b">Equipos Reemplazados</th>
            </tr>
          </thead>
          <tbody>
            {filteredInspections.map((inspection) => (
              <tr
                key={inspection.id}
                onClick={() => handleRowClick(inspection.id)}
                className={`cursor-pointer ${
                  selectedRow === inspection.id
                    ? "bg-gray-100"
                    : "hover:bg-gray-50"
                }`}
              >
                <td className="p-4 border-b">{inspection.id}</td>
                <td className="p-4 border-b">{inspection.Nombre}</td>
                <td className="p-4 border-b">
                  {formatTimestamp(inspection.Date)}
                </td>
                <td className="p-4 border-b">{inspection.Usuario}</td>
                <td
                  className={`p-4 border-b font-semibold ${
                    inspection.Status === "Correcto"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {inspection.Status}
                </td>
                <td className="p-4 border-b">{inspection.Unidad}</td>
                <td className="p-4 border-b">
                  {inspection.Comentario || "N/A"}
                </td>
                <td className="p-4 border-b">
                  {inspection.FusiblesReemplazados?.join(", ") || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InspectionList;
