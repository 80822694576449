import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import {
  Menu,
  Package,
  Users,
  Link,
  UserPlus,
  FileText,
  DollarSign,
  Briefcase,
  Phone,
  Shield,
} from "lucide-react";

const Administracion = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const categories = [
    {
      title: "Gestión General",
      items: [
        { path: "inventario", label: "Inventario", icon: <Package /> },
        { path: "enlaces", label: "Enlaces", icon: <Link /> },
        { path: "clientes", label: "Clientes", icon: <Users /> },
        { path: "NuevoEmpleado", label: "Nuevo Empleado", icon: <UserPlus /> },
        { path: "Inspecciones", label: "Mantenimiento Policial", icon: <Shield /> },
      ],
    },
    {
      title: "Finanzas",
      items: [
        { path: "ganancias", label: "Ganancias", icon: <DollarSign /> },
        { path: "facturas", label: "Facturas", icon: <FileText /> },
        { path: "gastos", label: "Gastos", icon: <Briefcase /> },
      ],
    },
    {
      title: "Contactos",
      items: [
        { path: "contactos", label: "Contactos", icon: <Phone /> },
      ],
    },
    {
      title: "Inventarios",
      items: [
        { path: "garantias", label: "Garantías", icon: <Shield /> },
      ],
    },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Barra Lateral */}
      <div
        className={`${
          isSidebarOpen ? "w-64" : "w-20"
        } bg-white shadow-lg transition-all duration-300 flex flex-col h-full overflow-y-auto`}
      >
        {/* Encabezado */}
        <div className="flex items-center justify-between p-4 border-b border-gray-300 sticky top-0 bg-white z-10">
          <h1
            className={`text-2xl font-bold ${
              isSidebarOpen ? "block" : "hidden"
            }`}
          >
            Administración
          </h1>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="p-2 rounded hover:bg-gray-200"
          >
            <Menu className="w-6 h-6 text-gray-700" />
          </button>
        </div>

        {/* Navegación */}
        <nav className="p-4 space-y-6">
          {categories.map((category) => (
            <div key={category.title}>
              <h2
                className={`text-sm font-semibold text-gray-500 uppercase ${
                  isSidebarOpen ? "block" : "hidden"
                }`}
              >
                {category.title}
              </h2>
              <div className="mt-2 space-y-2">
                {category.items.map((item) => (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={({ isActive }) =>
                      `flex items-center gap-4 p-3 rounded-lg text-lg font-medium ${
                        isActive
                          ? "bg-blue-500 text-white"
                          : "text-gray-700 hover:bg-gray-200"
                      }`
                    }
                  >
                    {item.icon}
                    <span className={`${isSidebarOpen ? "block" : "hidden"}`}>
                      {item.label}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </nav>
      </div>

      {/* Contenido Principal */}
      <div className="flex-1 p-6 bg-gray-50 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Administracion;
