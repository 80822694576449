import React, { useState, useEffect } from "react";
import { TextField, Box, Grid } from "@mui/material";

const Documentacion = ({ solicitudId, datosIniciales, onUpdate }) => {
  const [form, setForm] = useState(datosIniciales || {});

  useEffect(() => {
    setForm(datosIniciales || {});
  }, [datosIniciales]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);

    if (onUpdate) {
      onUpdate(updatedForm); // Actualizar en tiempo real
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Clave Única de Registro de Población (CURP)"
            name="curp"
            value={form.curp || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Registro Federal de Contribuyentes (RFC)"
            name="rfc"
            value={form.rfc || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Número de Seguridad Social"
            name="nss"
            value={form.nss || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="AFORE"
            name="afore"
            value={form.afore || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Pasaporte No."
            name="pasaporte"
            value={form.pasaporte || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Clase y Número de Licencia"
            name="licencia"
            value={form.licencia || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Documentacion;