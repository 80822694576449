import React, { useRef, useState, useEffect } from "react";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as tf from "@tensorflow/tfjs";
import Tesseract from "tesseract.js";

const PlateDetection = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [plateText, setPlateText] = useState(""); // Texto detectado en la placa
  const [loading, setLoading] = useState(false); // Estado de carga del OCR

  useEffect(() => {
    const loadModelAndStartDetection = async () => {
      console.log("Cargando modelo COCO-SSD...");
      const model = await cocoSsd.load();
      console.log("Modelo COCO-SSD cargado con éxito.");

      try {
        console.log("Solicitando acceso a la cámara...");
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        videoRef.current.srcObject = stream;

        videoRef.current.onloadedmetadata = () => {
          console.log("Cámara lista.");
          videoRef.current.play();
          detectPlate(model);
        };
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
      }
    };

    const detectPlate = async (model) => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const detect = async () => {
        // Validar dimensiones del video
        if (!video.videoWidth || !video.videoHeight) {
          requestAnimationFrame(detect);
          return;
        }

        // Configurar el canvas con las dimensiones del video
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Dibujar el video en el canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
          // Realizar detección de objetos
          const predictions = await model.detect(video);

          // Filtrar solo los autos detectados
          const cars = predictions.filter(
            (prediction) => prediction.class === "car" && prediction.score > 0.6
          );

          if (cars.length > 0) {
            const [x, y, width, height] = cars[0].bbox; // Tomar el primer auto detectado

            // Dibujar un rectángulo en la región del auto
            context.strokeStyle = "red";
            context.lineWidth = 2;
            context.strokeRect(x, y, width, height);

            // Capturar la región de la placa (ajustar la posición y tamaño si es necesario)
            const plateRegion = {
              x: x + width * 0.2,
              y: y + height * 0.6,
              width: width * 0.6,
              height: height * 0.2,
            };

            // Dibujar un rectángulo en la región de la placa
            context.strokeStyle = "blue";
            context.strokeRect(
              plateRegion.x,
              plateRegion.y,
              plateRegion.width,
              plateRegion.height
            );

            // Extraer la imagen de la placa
            const plateImage = context.getImageData(
              plateRegion.x,
              plateRegion.y,
              plateRegion.width,
              plateRegion.height
            );

            // Realizar OCR para obtener el texto de la placa
            processPlateImage(plateImage);
          }
        } catch (error) {
          console.error("Error durante la detección:", error);
        }

        requestAnimationFrame(detect);
      };

      detect();
    };

    const processPlateImage = async (imageData) => {
      if (loading) return; // Evitar llamadas duplicadas mientras se procesa OCR
      setLoading(true);

      try {
        // Crear un canvas temporal para convertir la región de la placa en imagen base64
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = imageData.width;
        tempCanvas.height = imageData.height;
        const tempContext = tempCanvas.getContext("2d");
        tempContext.putImageData(imageData, 0, 0);

        const plateBase64 = tempCanvas.toDataURL("image/png");

        // Procesar con Tesseract.js
        console.log("Procesando imagen de la placa con OCR...");
        const { data } = await Tesseract.recognize(plateBase64, "eng");
        const text = data.text.trim();
        console.log("Texto detectado:", text);

        setPlateText(text || "Texto no reconocido");
      } catch (error) {
        console.error("Error durante el OCR:", error);
      } finally {
        setLoading(false);
      }
    };

    loadModelAndStartDetection();

    return () => {
      // Detener la cámara al desmontar el componente
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        console.log("Cámara detenida.");
      }
    };
  }, [loading]);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Detección de Placas</h1>
      <div style={styles.videoWrapper}>
        <video ref={videoRef} style={styles.video} />
        <canvas ref={canvasRef} style={styles.canvas} />
      </div>
      <div style={styles.textWrapper}>
        {loading ? (
          <p style={styles.loading}>Procesando placa...</p>
        ) : (
          <p style={styles.text}>Texto de la placa: {plateText}</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
  },
  title: {
    fontSize: "24px",
    color: "#333",
    marginBottom: "20px",
  },
  videoWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "600px",
    aspectRatio: "16 / 9",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    marginBottom: "20px",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  canvas: {
    display: "none",
  },
  textWrapper: {
    width: "100%",
    maxWidth: "600px",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  text: {
    fontSize: "18px",
    color: "#333",
  },
  loading: {
    fontSize: "18px",
    color: "blue",
  },
};

export default PlateDetection;
