import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const NuevaMarca = () => {
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [modelos, setModelos] = useState([]);
  const [message, setMessage] = useState("");
  const [nextId, setNextId] = useState(1);
  const [marcasExistentes, setMarcasExistentes] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState("");

  useEffect(() => {
    const fetchMarcas = () => {
      const q = query(collection(db, "marcas"), orderBy("id", "asc"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const marcas = snapshot.docs.map((doc) => ({
          id: doc.data().id,
          nombre: doc.data().nombre,
          modelos: doc.data().modelos || [],
        }));

        setMarcasExistentes(marcas);

        if (marcas.length > 0) {
          setNextId(marcas[marcas.length - 1].id + 1);
        }
      });
      return unsubscribe;
    };

    fetchMarcas();
  }, []);

  const handleAddMarca = async () => {
    if (!marca.trim() && !marcaSeleccionada) {
      setMessage("El nombre de la marca es obligatorio.");
      return;
    }

    try {
      if (marcaSeleccionada) {
        const marcaDocRef = doc(db, "marcas", marcaSeleccionada);
        const marcaSeleccionadaDoc = marcasExistentes.find(
          (m) => m.id === parseInt(marcaSeleccionada)
        );

        const nuevosModelos = [...marcaSeleccionadaDoc.modelos, ...modelos];

        await updateDoc(marcaDocRef, {
          modelos: [...new Set(nuevosModelos)],
        });

        setMessage("Modelos agregados a la marca existente con éxito!");
      } else {
        const newDocRef = doc(db, "marcas", nextId.toString());

        await setDoc(newDocRef, {
          id: nextId,
          nombre: marca,
          modelos,
        });

        setMessage("Marca agregada con éxito!");
        setNextId(nextId + 1);
      }

      setMarca("");
      setModelo("");
      setModelos([]);
      setMarcaSeleccionada("");
    } catch (error) {
      console.error("Error al agregar la marca:", error);
      setMessage("Error al agregar la marca. Intenta nuevamente.");
    }
  };

  const handleAddModelo = () => {
    if (modelo.trim()) {
      setModelos((prev) => [...prev, modelo]);
      setModelo("");
    }
  };

  const handleRemoveModelo = (index) => {
    setModelos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Crear Nueva Marca o Agregar Modelos
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Seleccionar Marca
          </label>
          <select
            value={marcaSeleccionada}
            onChange={(e) => setMarcaSeleccionada(e.target.value)}
            className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
          >
            <option value="">
              <em>Nueva Marca</em>
            </option>
            {marcasExistentes.map((m) => (
              <option key={m.id} value={m.id.toString()}>
                {m.nombre}
              </option>
            ))}
          </select>
        </div>

        {!marcaSeleccionada && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Nombre de la Marca
            </label>
            <input
              type="text"
              value={marca}
              onChange={(e) => setMarca(e.target.value)}
              className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
            />
          </div>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Nombre del Modelo
          </label>
          <input
            type="text"
            value={modelo}
            onChange={(e) => setModelo(e.target.value)}
            className="w-full mt-1 p-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:outline-none"
          />
          <button
            onClick={handleAddModelo}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Agregar Modelo
          </button>
        </div>

        {modelos.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-800 mb-2">
              Modelos Nuevos:
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {modelos.map((mod, index) => (
                <div
                  key={index}
                  className="relative bg-gray-50 border border-gray-300 p-3 rounded-lg shadow"
                >
                  <p className="text-sm text-gray-800">{mod}</p>
                  <button
                    onClick={() => handleRemoveModelo(index)}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-600 transition"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <button
          onClick={handleAddMarca}
          className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
        >
          {marcaSeleccionada ? "Agregar Modelos" : "Crear Marca"}
        </button>

        {message && <p className="mt-4 text-sm text-gray-700">{message}</p>}
      </div>
    </div>
  );
};

export default NuevaMarca;
