import React, { useState } from "react";
import FormProducto from "./NuevoIventario/FormProducto";
import GaleriaImagenes from "./NuevoIventario/GaleriaImagenes";
import EscanerQR from "./NuevoIventario/EscanerQR";
import NuevaCategoria from "./NuevaCategoria";
import NuevaMarca from "./NuevaMarca";
import { Box, Grid } from "@mui/material";
import { Package, Tag, Camera } from "lucide-react"; // Iconos de Lucide

const Inventario = () => {
  const [imagenes, setImagenes] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [numInventario, setNumInventario] = useState(""); // Estado compartido para el número de inventario

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
        <h1 className="text-2xl font-bold text-gray-800 flex items-center gap-3 mb-6">
          <Package className="text-blue-500" />
          Agregar Producto al Inventario
        </h1>

        {/* Escáner QR */}
        <EscanerQR
          showQrScanner={showQrScanner}
          setShowQrScanner={setShowQrScanner}
          setNumInventario={setNumInventario} // Actualiza el estado del número de inventario
          setMessage={setMessage}
        />

        {message && (
          <p className="mt-4 text-sm text-gray-600 flex items-center gap-2">
            <Tag className="text-green-500" />
            {message}
          </p>
        )}

        {/* Formulario del Producto */}
        <FormProducto
          imagenes={imagenes}
          setImagenes={setImagenes}
          previewUrls={previewUrls}
          setPreviewUrls={setPreviewUrls}
          setMessage={setMessage}
          numInventario={numInventario} // Pasar el número de inventario al formulario
          setNumInventario={setNumInventario} // Permitir edición manual
        />

        {/* Galería de Imágenes */}
        <GaleriaImagenes
          imagenes={imagenes}
          previewUrls={previewUrls}
          setImagenes={setImagenes}
          setPreviewUrls={setPreviewUrls}
        />
      </div>

      {/* Categorías y Marcas */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <NuevaCategoria />
        </div>
        <div className="bg-white rounded-xl shadow-lg p-6">
          <NuevaMarca />
        </div>
      </div>
    </div>
  );
};

export default Inventario;
