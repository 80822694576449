import React, { useState } from "react";
import { db, storage } from "../../../firebase"; // Firestore y Storage
import { collection, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FirebaseRealtimeListener from "./FirebaseRealtimeListener";
import { Camera, Plus, Minus } from "lucide-react";

const FormProducto = ({
  imagenes,
  setImagenes,
  previewUrls,
  setPreviewUrls,
  setMessage,
  numInventario, // Número de inventario
  setNumInventario, // Para actualizar el número de inventario
}) => {
  const [titulo, setTitulo] = useState("");
  const [selectedCategoriaId, setSelectedCategoriaId] = useState("");
  const [selectedSubCategoria, setSelectedSubCategoria] = useState("");
  const [selectedMarcaId, setSelectedMarcaId] = useState("");
  const [selectedModelo, setSelectedModelo] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [subCategorias, setSubCategorias] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [stock, setStock] = useState(1);
  const [uploading, setUploading] = useState(false);

  // Escuchar categorías, marcas y clientes en tiempo real desde Firebase
  const { categorias, marcas, clientes } = FirebaseRealtimeListener();

  const handleCategoriaChange = (e) => {
    const categoriaId = e.target.value;
    setSelectedCategoriaId(categoriaId);

    const categoriaSeleccionada = categorias.find(
      (categoria) => categoria.id === categoriaId
    );

    setSubCategorias(categoriaSeleccionada?.subCategorias || []);
    setSelectedSubCategoria("");
  };

  const handleMarcaChange = (e) => {
    const marcaId = e.target.value;
    setSelectedMarcaId(marcaId);

    const marcaSeleccionada = marcas.find((marca) => marca.id === marcaId);
    setModelos(marcaSeleccionada?.modelos || []);
    setSelectedModelo("");
  };

  const increaseStock = () => setStock((prev) => prev + 1);
  const decreaseStock = () => setStock((prev) => (prev > 1 ? prev - 1 : prev));

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    setImagenes((prev) => [...prev, ...files]);
  };

  const handleImageUpload = async (docId) => {
    if (imagenes.length === 0) return [];
    setUploading(true);
    const uploadedUrls = [];

    for (let file of imagenes) {
      const storageRef = ref(storage, `inventory/${docId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            uploadedUrls.push(downloadURL);
            resolve();
          });
        });
      } catch {
        setUploading(false);
        setMessage("Error al subir las imágenes. Intenta nuevamente.");
        return [];
      }
    }

    setUploading(false);
    setMessage("Imágenes subidas con éxito!");
    return uploadedUrls;
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (imagenes.length === 0) {
      setMessage("Debes agregar al menos una imagen.");
      return;
    }

    try {
      const newDocRef = doc(collection(db, "inventory"));
      const uploadedUrls = await handleImageUpload(newDocRef.id);

      await setDoc(newDocRef, {
        titulo,
        numInventario,
        imagenes: uploadedUrls,
        categoriaId: selectedCategoriaId,
        subCategoria: selectedSubCategoria,
        marcaId: selectedMarcaId,
        modelo: selectedModelo,
        clienteId: selectedCliente,
        stock,
      });

      setMessage("Producto agregado con éxito!");
      setTitulo("");
      setNumInventario("");
      setSelectedCategoriaId("");
      setSelectedSubCategoria("");
      setSelectedMarcaId("");
      setSelectedModelo("");
      setSelectedCliente("");
      setStock(1);
      setImagenes([]);
      setPreviewUrls([]);
    } catch {
      setMessage("Error al agregar el producto. Intenta nuevamente.");
    }
  };

  return (
    <form
      onSubmit={handleAddProduct}
      className="flex flex-col space-y-4 bg-white p-6 shadow-md rounded-md"
    >
      <input
        type="text"
        placeholder="Título del Producto"
        value={titulo}
        onChange={(e) => setTitulo(e.target.value)}
        className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        required
      />

      <select
        value={selectedMarcaId}
        onChange={handleMarcaChange}
        className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        required
      >
        <option value="">Selecciona una marca</option>
        {marcas.map((marca) => (
          <option key={marca.id} value={marca.id}>
            {marca.nombre}
          </option>
        ))}
      </select>

      {modelos.length > 0 && (
        <select
          value={selectedModelo}
          onChange={(e) => setSelectedModelo(e.target.value)}
          className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        >
          <option value="">Selecciona un modelo</option>
          {modelos.map((modelo, index) => (
            <option key={index} value={modelo}>
              {modelo}
            </option>
          ))}
        </select>
      )}

      <input
        type="text"
        placeholder="Número de Inventario"
        value={numInventario}
        onChange={(e) => setNumInventario(e.target.value)}
        className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        required
      />

      <select
        value={selectedCategoriaId}
        onChange={handleCategoriaChange}
        className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        required
      >
        <option value="">Selecciona una categoría</option>
        {categorias.map((categoria) => (
          <option key={categoria.id} value={categoria.id}>
            {categoria.nombre}
          </option>
        ))}
      </select>

      {subCategorias.length > 0 && (
        <select
          value={selectedSubCategoria}
          onChange={(e) => setSelectedSubCategoria(e.target.value)}
          className="w-full p-3 border rounded focus:outline-none focus:ring focus:ring-blue-500"
        >
          <option value="">Selecciona una subcategoría</option>
          {subCategorias.map((subCategoria, index) => (
            <option key={index} value={subCategoria}>
              {subCategoria}
            </option>
          ))}
        </select>
      )}

      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={decreaseStock}
          className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          <Minus className="h-5 w-5" />
        </button>
        <input
          type="number"
          value={stock}
          readOnly
          className="w-16 text-center border rounded"
        />
        <button
          type="button"
          onClick={increaseStock}
          className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          <Plus className="h-5 w-5" />
        </button>
      </div>

      <label className="cursor-pointer flex items-center space-x-2">
        <Camera className="h-6 w-6 text-blue-500" />
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageSelect}
          className="hidden"
        />
        <span>Seleccionar Imágenes</span>
      </label>

      <button
        type="submit"
        className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 flex items-center justify-center"
        disabled={uploading}
      >
        {uploading ? "Subiendo..." : "Agregar Producto"}
      </button>
    </form>
  );
};

export default FormProducto;
