import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const partnerLogos = [
  "https://logowik.com/content/uploads/images/dji3302.logowik.com.webp",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Kenwood_Logo.svg.png?alt=media&token=3d201271-f254-40aa-9a34-47b9d39ea926",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Airbus_Logo_2017.svg.png?alt=media&token=d9f5d0fc-adbf-4f6e-9402-2c9d3f93adf8",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/hikvision-logo.png?alt=media&token=effa282c-314c-4dd5-b243-c142522f6316",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/unnamed.jpg?alt=media&token=263d2bc5-282c-43e3-b636-a7101f6458bb",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/LOG_DE0_41197_61861_165544815126313300_aeid_60__73_atomfeld_60_73-169314511655448164.png.png?alt=media&token=966d17ca-0fd9-49e0-88dc-ece95eaaab5c",
];

const PartnerSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gradient-to-r from-gray-50 to-gray-100 py-8 px-2 rounded-xl shadow-sm">
      <h2 className="text-center text-3xl font-semibold text-gray-800 mb-6 tracking-tight">
        Nuestros Aliados Estratégicos
      </h2>
      <Slider {...settings}>
        {partnerLogos.map((logo, index) => (
          <div key={index} className="px-1 flex justify-center">
            <div
              className="bg-white rounded-xl shadow-md 
              w-24 h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 
              flex justify-center items-center 
              transform transition-all duration-300 
              hover:scale-105 hover:shadow-lg"
            >
              <img
                src={logo}
                alt={`Partner logo ${index + 1}`}
                className="max-w-[80%] max-h-[80%] object-contain opacity-80 hover:opacity-100 transition-opacity"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnerSlider;
